import { useSearchParams } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import {
  type DtoOrgSubscription,
  type DtoProduct,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import {
  type Organization,
  OrganizerRole,
  OrganizerRoleUtils,
} from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { SegmentedControl } from '../common/SegmentedControl';
import { Loading } from '../Loading';
import { useOrganization, useOrganizationSubscription } from '../Organization';
import { useUser } from '../UserContext';
import { NonmarketedSubscriptionCard, ProductCard } from './ProductCard';
import { useCancelMySubscription } from './SubscriptionCancel';
import { useBillingIntervalOptions } from './useBillingIntervalOptions';
import { useMarketedProducts } from './useProducts';
import { CONFIRM_SUBSCRIPTION_CHANGE_URL, ProductSearchParam } from './utils';

export function SubscriptionChange() {
  const orgId = useUser().organizer?.orgId;
  if (!orgId) {
    return (
      <div className='w-full h-full flex items-center justify-center text-white text-lg'>
        This feature is only available for organization members.
      </div>
    );
  }

  return <SubscriptionChangeInternal orgId={orgId} />;
}

function SubscriptionChangeInternal(props: { orgId: string }) {
  const {
    data: organization,
    isLoading: isOrgLoading,
    error: orgLoadingError,
  } = useOrganization(props.orgId);

  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    error: subscriptionLoadingError,
  } = useOrganizationSubscription(props.orgId);

  const {
    data: marketedProducts,
    isLoading: areProductsLoading,
    error: productsLoadingError,
  } = useMarketedProducts();

  let body;
  if (isOrgLoading || areProductsLoading || isSubscriptionLoading) {
    body = (
      <div className='w-full h-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  } else if (
    !organization ||
    !marketedProducts ||
    !subscription ||
    orgLoadingError ||
    productsLoadingError ||
    subscriptionLoadingError
  ) {
    console.error({
      orgLoadingError,
      productsLoadingError,
      subscriptionLoadingError,
    });
    body = (
      <div className='w-full h-full flex items-center justify-center'>
        <p className='text-white text-center'>
          Whoops, looks like we had some trouble loading this page. Please try
          again.
        </p>
      </div>
    );
  } else {
    body = (
      <ProductPricingTable
        {...props}
        organization={organization}
        subscription={subscription}
        marketedProducts={marketedProducts}
      />
    );
  }

  return (
    <div className='w-full h-full text-white'>
      <div className='relative w-full h-full bg-black rounded-xl'>
        <div className='absolute w-full h-128 overflow-hidden bg-pay-wall' />
        <div className='relative w-full h-full'>{body}</div>
      </div>
    </div>
  );
}

function ReachOutToOrgOwnersAdmins(props: { organization: Organization }) {
  const {
    data: grouped,
    isLoading,
    error,
  } = useSWR(
    [props.organization.id, `/api/organization/owners-admins`],
    async () => {
      const admins = await apiService.organization
        .getOrganizers(props.organization.id, { role: OrganizerRole.Admin })
        .next();
      const owners = await apiService.organization
        .getOrganizers(props.organization.id, { role: OrganizerRole.Owner })
        .next();
      return { admins, owners };
    }
  );

  if (isLoading) return null;

  const owners = grouped?.owners.length ? (
    <div>
      <dt className='font-bold'>Owner</dt>
      <dd className='flex flex-col gap-4'>
        {grouped?.owners.map((o) => (
          <div key={o.uid}>
            {o.firstName} {o.lastName} <br />
            {o.email}
          </div>
        ))}
      </dd>
    </div>
  ) : null;

  const admins = grouped?.admins.length ? (
    <div>
      <dt className='font-bold'>Admins</dt>
      <dd className='flex flex-col gap-4'>
        {grouped?.admins.map((o) => (
          <div key={o.uid}>
            {o.firstName} {o.lastName} <br />
            {o.email}
          </div>
        ))}
      </dd>
    </div>
  ) : null;

  return (
    <div className='w-260 flex'>
      <div
        className='
          w-1/2 flex flex-col gap-7
          items-center
          font-bold text-3.5xl
        '
      >
        <div className='text-center'>
          Get <span className='text-tertiary'>Unlimited Access</span> to 100+
          Team Building Experiences
        </div>
        <img
          height={164}
          src={getStaticAssetPath('images/team-building-sm.png')}
          alt='Rounded rectangled people smiling, with mouse arrows from each pointing to a Luna Park logo in the center'
        />
        <div className='text-center'>Upgrade your account today!</div>
      </div>
      <div className='w-1/2 flex flex-col gap-4'>
        <div className='text-xl text-tertiary'>
          Reach out to one of your account admins to request an upgrade:
        </div>
        {error && <div className=''>Error loading admins and owners</div>}
        <dl className='flex flex-col gap-4'>
          {owners}
          {admins}
        </dl>
      </div>
    </div>
  );
}

export function ProductPricingTable(props: {
  organization: Organization;
  subscription: DtoOrgSubscription;
  marketedProducts: DtoProduct[];
  hideLegacyCard?: boolean;
}) {
  const { organization, subscription, marketedProducts, hideLegacyCard } =
    props;

  const navigate = useNavigate();
  const cancelSubscription = useCancelMySubscription();
  const [searchParams] = useSearchParams();

  const [orgSizeWarning, setOrgSizeWarning] = useState<
    { requestedSize: number } | undefined
  >();

  const currentOrgMaxSize =
    organization.maxSize ?? organization.organizersCount;

  const user = useUser();

  const canModifyPlan = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer?.role);
  const contactBilling =
    subscription.plan !== 'free' && !subscription.subscriptionId;

  const handleUpgrade = useLiveCallback(
    (product: DtoProduct, price: ModelsPrice | null, trial: boolean) => {
      const isCancellation = price === null || price.amount === 0;
      if (
        !isCancellation &&
        price?.maxSeats &&
        price.maxSeats < organization.organizersCount
      ) {
        setOrgSizeWarning({ requestedSize: price.maxSeats });
        return;
      }

      const params = new URLSearchParams();
      params.set(ProductSearchParam.TargetProduct, product.id);
      if (price) {
        params.set(ProductSearchParam.TargetPrice, price.id);
      }
      if (trial) {
        params.set(ProductSearchParam.Trial, 'true');
      }
      const redirectTo = searchParams.get(ProductSearchParam.RedirectTo);
      if (redirectTo) {
        params.set(ProductSearchParam.RedirectTo, redirectTo);
      }
      navigate(`${CONFIRM_SUBSCRIPTION_CHANGE_URL}?${params.toString()}`);
    }
  );

  const handleCancel = useLiveCallback(() => {
    cancelSubscription();
  });

  const billingIntervalOptions = useBillingIntervalOptions(marketedProducts);
  const [billingInterval, setBillingInterval] = useState(() => {
    let billingInterval = billingIntervalOptions[0];
    if (subscription.billingInterval) {
      const maybeBillingInterval = billingIntervalOptions.find(
        (i) => i.value === subscription.billingInterval
      );
      if (maybeBillingInterval) {
        billingInterval = maybeBillingInterval;
      }
    }
    return billingInterval;
  });

  const legacyProductCard = useMemo(() => {
    if (hideLegacyCard) return null;
    if (!subscription.productId) {
      return null;
    }
    // look for the subscriptions productId in the marketed products.
    const maybeProduct = marketedProducts.find(
      (p) => p.id === subscription.productId
    );
    if (maybeProduct) {
      // the product is already visible, let's check if the price is too.
      const maybePrice = maybeProduct.prices?.find(
        (p) => p.id === subscription.priceId
      );
      if (!maybePrice?.archived) {
        // the price is visible, so we don't need to show the legacy card.
        return null;
      }
    }
    return (
      <NonmarketedSubscriptionCard
        subscription={subscription}
        maxSeats={organization.maxSize}
        onClickCancel={handleCancel}
        disabled={!canModifyPlan}
      />
    );
  }, [
    hideLegacyCard,
    subscription,
    marketedProducts,
    organization.maxSize,
    handleCancel,
    canModifyPlan,
  ]);

  const productCards = useMemo(() => {
    const noYourPlanLabel = legacyProductCard !== null;
    return (
      <div className='flex items-center justify-center gap-4'>
        {marketedProducts.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            preSelectForSeats={currentOrgMaxSize}
            disabled={!canModifyPlan || contactBilling}
            onClickSelect={handleUpgrade}
            onClickCancel={handleCancel}
            onSelectedPriceChange={() => setOrgSizeWarning(undefined)}
            currentSubscription={subscription}
            canTrial={organization.canTrial}
            billingInterval={billingInterval.value}
            noYourPlanLabel={noYourPlanLabel}
          />
        ))}
      </div>
    );
  }, [
    legacyProductCard,
    marketedProducts,
    currentOrgMaxSize,
    canModifyPlan,
    contactBilling,
    handleUpgrade,
    handleCancel,
    subscription,
    organization.canTrial,
    billingInterval.value,
  ]);

  const mainProducts = (
    <>
      <main className='py-12 flex flex-col items-center justify-center gap-4'>
        {legacyProductCard && (
          <div className='w-full flex-none'>{legacyProductCard}</div>
        )}

        {billingIntervalOptions.length > 1 && (
          <div className='pb-8'>
            <SegmentedControl
              options={billingIntervalOptions}
              value={billingInterval}
              onChange={(v) => setBillingInterval(v)}
            />
          </div>
        )}

        {productCards}
      </main>
      <footer className='h-20 px-40 flex items-center'>
        {contactBilling ? (
          <div className='text-tertiary text-center pb-6'>
            We'd be happy to help you change your plan. Please contact us at{' '}
            <a
              href='mailto:billing@lunapark.com'
              className='underline'
              target='_blank'
              rel='noreferrer'
            >
              billing@lunapark.com
            </a>
          </div>
        ) : orgSizeWarning ? (
          <div className='text-red-002 text-center pb-6'>
            You have selected a plan for {orgSizeWarning.requestedSize} users
            but you currently have {props.organization.organizersCount} users
            with accounts.
            <br />
            Please visit settings and remove excess users before making this
            change to your plan.
          </div>
        ) : null}
      </footer>
    </>
  );

  const mainReachOut = (
    <main className='py-12'>
      <ReachOutToOrgOwnersAdmins organization={organization} />
    </main>
  );

  return (
    <div className='relative w-full h-full flex flex-col items-center'>
      <header className='w-full pt-10 px-10 text-center'>
        <div className='text-3.5xl font-bold'>
          Team engagement solutions at a price that works!
        </div>
        <div className='pt-2 font-bold'>
          Build lasting connections at a fraction of the price of in-person
          meetups.
        </div>
      </header>
      {!canModifyPlan ? mainReachOut : mainProducts}
    </div>
  );
}
